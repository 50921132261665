import React, { useEffect, useState, useRef } from "react";

import manual from "./icons/manual.png";
import pin from "./icons/pin.png";
import UserManual from "../UserManual/UserManual";
import Button from "../Button/Button";
import Unlockscreen from "../unlockScreen/Unlockscreen";
import axios from "axios";
import "./Style.css";
import { useGlobalContext } from "../Context/Context";
import Accounts from "../Accounts/Accounts";
import ChangePassword from "../ChangePassword/ChangePassword";
import TrustFolder from "../TrustFolder/TrustFolder";
import PinUnlockLog from "../pinUnlockLog/PinUnlockLog";
import UnlockscreenLog from "../UnlockscreenLog/UnlockscreenLog";
import DefaultPage from "../DefaultPage/DefaultPage";
import StyleScreen from "../StyleScreen/StyleScreen";

function DefinePin({
  selectAccount,
  setSelectAccount,
  setCurrentPage,
  setSelectAccountPin,
  selectAccountPin,
}) {
  const [password, setPassword] = useState(false);
  const { loggedUser, setLoggedUser } = useGlobalContext();
  const { typeButton } = useGlobalContext();
  const [isPattern, setIsPattern] = useState(false);
  const [resetSwitch, setResetSwitch] = useState(false);
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );
  const { apiKey } = useGlobalContext();
  const isDownMouse = useRef();
  const noEvent = useRef();

  console.log("Setovani user", loggedUser);

  const { baseUrl } = useGlobalContext();
  const [selectAccountPattern, setSelectAccountPattern] = useState([]); //treba loggedUser.pattern

  const handleOpenUserManual = (e) => {
    console.log(e.currentTarget);
    setSelectAccountPin(2);
  };

  const handleUnlockUp = () => {
    console.log("Pozvao se up", isDownMouse.current, noEvent.current);

    if (noEvent.current && isDownMouse.current) {
      setIsPattern(true);
      setSelectAccountPattern([]);
      noEvent.current = false;
    }
  };

  const handleUnlockMove = () => {
    if (noEvent.current && isDownMouse.current) {
      setIsPattern(false);
      noEvent.current = false;
    }
  };

  const handleUnlockDown = () => {
    if (noEvent.current) {
      isDownMouse.current = true;
      console.log("Pozvao se down", isDownMouse.current);
    }
  };

  const handleOpenAccount = (e) => {
    console.log(e.currentTarget);
    setSelectAccountPin(1);
  };

  const handleOpenSetPassword = (e) => {
    setSelectAccountPin(3);
  };

  window.addEventListener("resize", () => {
    setIsLandscape(window.innerWidth > window.innerHeight);
    console.log("Resize");
  });

  useEffect(() => {
    noEvent.current = true;
    isDownMouse.current = false;

    return () => {
      setSelectAccountPin();
    };
  }, []);

  useEffect(() => {
    const handleCheckPattern = async () => {
      console.log(selectAccountPattern);
      let localPattern = "";
      selectAccountPattern.forEach((pattern) => {
        localPattern += pattern.toString();
      });
      console.log(localPattern);
      if (isPattern) {
        localPattern = localPattern.slice(1); // Provera da li je odabran unos brojevima, ako jeste prva cifra se brise
      }
      try {
        const { data } = await axios.post(
          `${baseUrl}/auth/checkUserPattern.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
            user_pattern: localPattern,
          }
        );

        if (data.status === 200) {
          console.log("Usao");
          const newLoggedUser = { ...loggedUser, isPatternSet: true };
          localStorage.setItem("editor-user", JSON.stringify(newLoggedUser));
          setSelectAccountPin(1);
          loggedUser.pattern = localPattern;
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (loggedUser.pattern.length !== 0) handleCheckPattern();
  }, [selectAccountPattern]);

  console.log("Render definePin", selectAccount);
  console.log("Render definePin", selectAccountPin);
  return (
    <div className="definePin-container">
      {selectAccountPin === 0 && (
        <div
          className={
            !isLandscape
              ? "loggin-pin-container"
              : "loggin-pin-container-landscape"
          } /*onClick={handleOpenAccount}*/
        >
          <div className="manual">
            <a href="#" onClick={handleOpenUserManual}>
              <img src={manual} alt="manual" />
            </a>
          </div>
          {loggedUser.pattern.length !== 0 ? (
            <div
              className="pin-or-pattern-manual"
              onMouseDown={handleUnlockDown}
              onMouseMove={handleUnlockMove}
              onMouseUp={handleUnlockUp}
            >
              {isPattern ? (
                <PinUnlockLog
                  height={220}
                  width={242}
                  setInputPattern={setSelectAccountPattern}
                  draft={true}
                  resetSwitch={resetSwitch}
                  setResetSwitch={setResetSwitch}
                  setCurrentPage={setCurrentPage}
                  pin={selectAccountPattern}
                />
              ) : (
                <UnlockscreenLog
                  setCurrentPage={setCurrentPage}
                  type={"second"}
                  winPattern={""}
                  draft={true}
                  setInputPattern={setSelectAccountPattern}
                />
              )}
            </div>
          ) : (
            <div className="pinBtn" onClick={handleOpenSetPassword}>
              <Button typeButton={typeButton}>
                <img src={pin} alt="pin" />
              </Button>
            </div>
          )}
        </div>
      )}

      {selectAccountPin === 1 && (
        <Accounts
          selectAccount={selectAccount}
          setSelectAccount={setSelectAccount}
          setSelectAccountPin={setSelectAccountPin}
          setCurrentPageGlobal={setCurrentPage}
        />
      )}
      {selectAccountPin === 2 && (
        <UserManual setSelectAccountPin={setSelectAccountPin} />
      )}
      {selectAccountPin === 3 && (
        <ChangePassword
          setCurrentPage={setCurrentPage}
          setSelectAccountPin={setSelectAccountPin}
          type={true}
        />
      )}
      {selectAccountPin === 4 && (
        <TrustFolder setCurrentPage={setCurrentPage} type={true} />
      )}
      {selectAccountPin === 5 && (
        <ChangePassword
          setCurrentPage={setCurrentPage}
          setSelectAccountPin={setSelectAccountPin}
          type={false}
        />
      )}
      {selectAccountPin === 6 && (
        <TrustFolder
          setCurrentPage={setCurrentPage}
          type={false}
          setSelectAccountPin={setSelectAccountPin}
        />
      )}
      {selectAccountPin === 7 && (
        <DefaultPage
          setSelectAccount={setSelectAccount}
          setSelectAccountPin={setSelectAccountPin}
        ></DefaultPage>
      )}
      {selectAccountPin === 8 && (
        <StyleScreen
          setSelectAccount={setSelectAccount}
          setSelectAccountPin={setSelectAccountPin}
        ></StyleScreen>
      )}
    </div>
  );
}

export default DefinePin;
