import React, { useState, useRef, useEffect } from "react";
import "./Style.css";
import DefinePin from "../DefinePin/DefinePin";
import user from "../picture/User.png";
import house from "../picture/House.png";
import share from "../picture/Share.png";
import calendar from "../picture/Calendar.png";
import bell from "../picture/Bell.png";
import home from "../picture/Home.png";
import notes from "../picture/Notes.png";
import poruke from "../picture/Message.png";
import settings from "../picture/Settings.png";
import forum from "../picture/forum.png";
import { useGlobalContext } from "../Context/Context";
import Button from "../Button/Button";

function Sidebar({ setCurrentPage, currentPage }) {
  const landscape = 700;
  const { loggedUser } = useGlobalContext();
  const { typeButton, useSiteBar, setUseSiteBar } = useGlobalContext();
  const [chooseOption, setChooseOption] = useState({ index: 0, value: false });
  const [isVisible, setIsVisible] = useState(false);
  const [selectAccount, setSelectAccount] = useState(
    loggedUser.defaultHomePage
  );
  const [selectAccountPin, setSelectAccountPin] = useState(
    loggedUser.isPatternSet ? 1 : 0
  );

  const startY = useRef(0);
  const startX = useRef(0);
  const isLandscape = useRef(window.innerWidth > landscape);
  const sidebarRef = useRef(null); // Sidebar reference

  const handleStyleDefinePin = (visible) => {
    /*const definePin = document.querySelector(".definePin-container");
    const userManual = document.querySelector(".user-manual-wrapper");
    if (definePin !== null) {
      definePin.style.width = "100vw";
    }
    if (userManual !== null) {
      if (!visible) {
        userManual.style.width = `100vw`;
      } else {
        userManual.style.width = "100vw";
      }
    }*/
  };

  const handleBackHome = () => {
    setSelectAccount(0);
    if (selectAccountPin !== 0) setSelectAccountPin(1);
    if (isLandscape.current) {
      setIsVisible(false);
    } else {
      setIsVisible(false);
      handleStyleDefinePin(false);
    }
  };

  const handleSettings = () => {
    if (selectAccount === 4) {
      setSelectAccount(0);
      if (selectAccountPin !== 0) setSelectAccountPin(1);
    } else {
      setSelectAccount(4);
    }
    if (isLandscape.current) {
      setIsVisible(false);
    } else {
      setIsVisible(false);
      handleStyleDefinePin(false);
    }
  };

  // const handleLinkLocation = () => {
  //   window.location.href = "/social/feed";
  //   if (isLandscape.current) {
  //     setIsVisible(true);
  //   } else {
  //     setIsVisible(false);
  //     handleStyleDefinePin(false);
  //   }
  // };

  const handleLinkLocation = (index) => {
    // Perform the navigation
    window.location.href = "/social";
    if (selectAccountPin !== 0) setSelectAccountPin(1);
    // Then, reset the button's active state after a short delay
    setChooseOption({ index: index, value: true });
    setTimeout(() => {
      setChooseOption({ index: 0, value: false });
    }, 200); // Adjust the delay if needed
  };

  const handleMessage = () => {
    if (selectAccount === 5 || selectAccount === 13) {
      setSelectAccount(0);
    } else {
      if (selectAccountPin !== 0) setSelectAccountPin(1);
      setSelectAccount(5);
    }
    if (isLandscape.current) {
      setIsVisible(false);
    } else {
      setIsVisible(false);
      handleStyleDefinePin(false);
    }
  };

  const handleProfile = () => {
    if (selectAccount === 12) {
      setSelectAccount(0);
    } else {
      if (selectAccountPin !== 0) setSelectAccountPin(1);
      setSelectAccount(12);
    }
    if (isLandscape.current) {
      setIsVisible(false);
    } else {
      setIsVisible(false);
      handleStyleDefinePin(false);
    }
  };

  const isNearSidebar = (x) => {
    const sidebar = sidebarRef.current;
    if (!sidebar) return false;

    const sidebarRect = sidebar.getBoundingClientRect();
    const proximityThreshold = 20; // Adjust this value to control the proximity

    // Check if the drag starts near or on the sidebar (within the proximity threshold)
    return x >= sidebarRect.left - proximityThreshold && x <= sidebarRect.right;
  };

  const handleMouseMove = (e) => {
    const deltaY = getClientY(e) - startY.current;
    const deltaX = getClientX(e) - startX.current;
    if (isLandscape.current) {
      if (deltaY > -10) {
        setIsVisible(true);
      } else if (deltaY < 10) {
        setIsVisible(false);
      }
    } else {
      if (deltaX < -10) {
        setIsVisible(true);
        handleStyleDefinePin(true);
      } else if (deltaX > 10) {
        setIsVisible(false);
        handleStyleDefinePin(false);
      }
    }
  };

  const handleTouchMove = (e) => {
    const deltaY = getClientY(e) - startY.current;
    const deltaX = getClientX(e) - startX.current;
    if (isLandscape.current) {
      if (deltaY > -10) {
        setIsVisible(true);
      } else if (deltaY < 10) {
        setIsVisible(false);
      }
    } else {
      if (deltaX < -10) {
        setIsVisible(true);
        handleStyleDefinePin(true);
      } else if (deltaX > 10) {
        setIsVisible(false);
        handleStyleDefinePin(false);
      }
    }
  };

  const handleMouseDown = (e) => {
    if (isNearSidebar(e.clientX)) {
      startY.current = getClientY(e);
      startX.current = getClientX(e);

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    if (isNearSidebar(touch.clientX)) {
      startY.current = getClientY(e);
      startX.current = getClientX(e);

      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    }
  };

  const handleTouchEnd = () => {
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("touchend", handleTouchEnd);
  };

  useEffect(() => {
    const app = document.querySelector(".app"); // Listen to events on the app instead of just sidebar

    if (app) {
      app.addEventListener("mousedown", handleMouseDown);
      app.addEventListener("touchstart", handleTouchStart);
    }

    return () => {
      if (app) {
        app.removeEventListener("mousedown", handleMouseDown);
        app.removeEventListener("touchstart", handleTouchStart);
      }
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isVisible]);

  const getClientY = (e) => {
    if (e.touches !== undefined) {
      return e.touches[0].clientY;
    } else return e.clientY;
  };

  const getClientX = (e) => {
    if (e.touches !== undefined) {
      return e.touches[0].clientX;
    } else return e.clientX;
  };

  const handleChoseOption = (index) => {
    // Set the active state of the button
    setChooseOption({ index: index, value: true });

    // Add logic to reset the button's active state after a short delay
    if ([1, 2, 3, 4].includes(index)) {
      // Reset for the second button (index 1), share button (index 4), and the others (index 5, 6, 8)
      setTimeout(() => {
        setChooseOption({ index: 0, value: false });
      }, 200); // Delay before resetting (adjust if needed)
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "TEMPOLEX",
          text: "Registruj se besplatno!",
          url: "https://tempolex.nadzor.app",
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      alert("Sharing is not supported on this browser.");
    }
  };

  return (
    <>
      <DefinePin
        selectAccount={selectAccount}
        setSelectAccount={setSelectAccount}
        setCurrentPage={setCurrentPage}
        setSelectAccountPin={setSelectAccountPin}
        selectAccountPin={selectAccountPin}
      />
      <div
        className={`mask ${isVisible ? "visible" : "hidden"}`}
        onClick={() => setIsVisible(false)}
      />
      <div
        ref={sidebarRef}
        className={
          window.innerWidth > 700
            ? `sidebar sidebar-landscape ${isVisible ? "visible" : "hidden"}`
            : `sidebar ${isVisible ? "visible" : "hidden"}`
        }
        style={
          selectAccountPin === 0
            ? { pointerEvents: "none" }
            : { pointerEvents: "auto" }
        }
      >
        <div className="back-home-button" onClick={handleProfile}>
          <Button active={selectAccount === 12} typeButton={typeButton}>
            <img src={user} alt="user" />
          </Button>
        </div>
        {/* <div className="back-home-button" onClick={handleLinkLocation()} >
          <Button active={selectAccount === 6} typeButton={typeButton}>
            <img src={forum} alt="forum" />
          </Button>
        </div> */}
        <div className="back-home-button" onClick={() => handleLinkLocation(6)}>
          <Button active={chooseOption.index === 6} typeButton={typeButton}>
            <img src={forum} alt="forum" />
          </Button>
        </div>

        <div className="back-home-button" onClick={handleBackHome}>
          <Button active={selectAccount === 0} typeButton={typeButton}>
            <img src={home} alt="home" />
          </Button>
        </div>
        <div className="back-home-button" onClick={() => handleChoseOption(1)}>
          <Button typeButton={typeButton} active={chooseOption.index === 1}>
            <img src={notes} alt="notes" />
          </Button>
        </div>
        <div className="back-home-button" onClick={() => handleChoseOption(2)}>
          <Button typeButton={typeButton} active={chooseOption.index === 2}>
            <img src={calendar} alt="calendar" />
          </Button>
        </div>
        <div className="back-home-button" onClick={() => handleChoseOption(3)}>
          <Button typeButton={typeButton} active={chooseOption.index === 3}>
            <img src={bell} alt="bell" />
          </Button>
        </div>
        <div className="back-home-button" onClick={handleMessage}>
          <Button
            active={selectAccount === 5 || selectAccount === 13}
            typeButton={typeButton}
          >
            <img src={poruke} alt="poruke" />
          </Button>
        </div>
        <div className="back-home-button" onClick={() => handleChoseOption(4)}>
          <Button typeButton={typeButton} active={chooseOption.index === 4}>
            <img src={share} alt="share" onClick={() => handleShare()} />
          </Button>
        </div>

        <div className="back-home-button" onClick={handleSettings}>
          <Button active={selectAccount === 4} typeButton={typeButton}>
            <img src={settings} alt="settings" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
