import React, { useEffect } from 'react'
import { NavMenu } from '../../components'
import { Outlet, useLocation } from 'react-router-dom'
import LandingPage from '../LandingPage/LandingPage'
import { BackActiveIcon, BackIdleIcon, BellActiveIcon, BellIdleIcon, SearchActiveIcon, SearchIdleIcon, UserActiveIcon, UserIdleIcon } from '../../assets'

const RootPage = () => {

    const location = useLocation()

  return (
    location.pathname === '/social' || location.pathname === '/social/' ?
    <LandingPage />
    :
    <>
    <NavMenu />
    <Outlet />
    </>
  )
}

export default RootPage