import { useEffect, useState } from "react";
import axios from "axios";

import { useGlobalContext } from "../../Context/Context";
import { fetchDataByLinkBody, fetchUsersByLinkBody } from "./fetchAPI";


export const useFetchPosts = () => {
    const [posts, setPosts] = useState({});
  
    const { apiKey, loggedUser } = useGlobalContext();
    const link = "/relations/posts/readAllPosts.php"
  
    const body = {
        api_key: apiKey,
        user_id: loggedUser?.id,
        profile_id: loggedUser?.profileId,
    };
    
    useEffect(() => {
      try {
        fetchDataByLinkBody(link,body).then(data => {console.log(data); setPosts(data)})
      } catch (error) {
        console.log(error)
      }
    }, [])
    return posts
};

export const useFetchUsers = () => {
  const [users, setUsers] = useState({});

  const { apiKey, loggedUser } = useGlobalContext();
  const link = "/relations/profiles_forum/profilesUnion.php"

  const body = {
      api_key: apiKey,
      user_id: loggedUser?.id,
      profile_id: loggedUser?.profileId,
  };
  
  useEffect(() => {
    try {
      fetchUsersByLinkBody(link,body).then(data => setUsers(data))
    } catch (error) {
      console.log(error)
    }
  }, [])
  return users
};