import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import CircledButton from '../buttons/circled-button/CircledButton'
import { BackActiveIcon, BackIdleIcon, BellActiveIcon, BellIdleIcon, SearchActiveIcon, SearchIdleIcon, 
    UserActiveIcon, UserIdleIcon,
    GearActiveIcon,
    GearIdleIcon,
    OtherActiveIcon,
    OtherIdleIcon,
    UserfeedIdleIcon,
    UserfeedActiveIcon} 
from '../../assets'

import './navMenu.css'


const NavMenu = () => {
    const [buttonsList, setButtonsList] = useState([])
    const navigate = useNavigate()

    const navigateToPreviousPage = () => {
      const currentList = location.pathname.split("/")
      currentList.shift()
      currentList.shift()
      console.log(currentList.length)
      if (currentList.length > 2) navigate(-1)
      else if (currentList.length > 1) {currentList.pop(); navigate(currentList.join('/'))}
      else if (currentList.length == 1) {
        if(currentList.toString() === 'feed') navigate('../')
        else navigate('feed')
      }
    }

    const backButton = {idleImage: BackIdleIcon, activeImage: BackActiveIcon,handleEffect: navigateToPreviousPage , toggle: false}
    
    const homeNavButtons = [backButton,
        {link: 'personal', idleImage: UserIdleIcon, activeImage: UserActiveIcon, handleEffect: () => {console.log('2')}, toggle: true},
        {link: 'notifications', idleImage: BellIdleIcon, activeImage: BellActiveIcon, handleEffect: () => {console.log('3')}, toggle: true},
        {link: 'search', idleImage: SearchIdleIcon, activeImage: SearchActiveIcon, handleEffect: () => {console.log()}, toggle: true}
    ]

    const personalButtons = [backButton,
      {link: 'personal', idleImage: UserfeedIdleIcon, activeImage: UserfeedActiveIcon, handleEffect: () => {console.log('2')}, toggle: true},
      {link: 'personal/user-bloc', idleImage: OtherIdleIcon, activeImage: OtherActiveIcon, handleEffect: () => {console.log('3')}, toggle: true},
      {link: 'personal/settings', idleImage: GearIdleIcon, activeImage: GearActiveIcon, handleEffect: () => {console.log('4')}, toggle: true}
    ]

    const location = useLocation()

    useEffect(() => {
      switch (location.pathname.substring(0,16)) {
        case '/social/personal':
            setButtonsList(personalButtons)
            break;
        default:
            setButtonsList(homeNavButtons)
            break;
      }
    }, [location])
    
  return (
    <nav className="top-buttons">
        {buttonsList.map((button, key) => {
          let current = false
          if (location.pathname.substring(8) === button.link) current = true;
            return (
              button.link ? <NavLink to={button.link} key={key} className={isActive => isActive && 'current-link'}>
                <CircledButton
                    toggle={button.toggle} 
                    idleImage={button.idleImage} 
                    activeImage={button.activeImage} 
                    handleButtonEffect={button.handleEffect}
                    current={current}
                />
                </NavLink> :
                <CircledButton
                key={key}
                toggle={button.toggle} 
                idleImage={button.idleImage} 
                activeImage={button.activeImage} 
                handleButtonEffect={button.handleEffect}
                current={current}
                />
              )
            }
          )}
    </nav>
  )
}

export default NavMenu