import React, { useEffect, useState } from 'react'

import { CircledButton, FeedPostsList, Loader, StickyMenu } from '../../components';
import { CategoryActiveIcon, CategoryIdleIcon, EyeIdleIcon, FavIdleIcon, SortIdleIcon } from '../../assets';

import { fetchPopularPosts } from '../../data/fetchAPI';

const FeedPosts = () => {

    const [postList, setPostList] = useState([])

    const [selectedCategory, setSelectedCategory] = useState(0)
    const [isCategorySelection, setIsCategorySelection] = useState(false)
    const handleCategoryClick = (num) => 
      {if (selectedCategory === num) setSelectedCategory(0) 
      else setSelectedCategory(num)}
    
    const [isRemainedActive, setIsRemainedActive] = useState(false)
    
    const toggleCategories = () => {
    if(selectedCategory !== 0) setIsRemainedActive(true)
    else setIsRemainedActive(false)
    setIsCategorySelection(!isCategorySelection)
    console.log(isRemainedActive)
    }
  

    const stickyButtons = [
      {link: './', idleImage: CategoryIdleIcon, activeImage: CategoryActiveIcon, handleEffect: toggleCategories, toggle: true, current:isRemainedActive, type: 'category-button'},
      {link: './', idleImage: EyeIdleIcon, activeImage: EyeIdleIcon, handleEffect: () => {console.log('2')}, toggle: true},
      {link: './', idleImage: SortIdleIcon, activeImage: SortIdleIcon, handleEffect: () => {console.log('3')}, toggle: false},
      {link: './', idleImage: FavIdleIcon, activeImage: FavIdleIcon, handleEffect: () => {console.log('4')}, toggle: false}
    ]
    
    useEffect(() => {
        fetchPopularPosts().then(data => setPostList(data))
    }, [selectedCategory])
    
    

    return (
        <div className='flex-center-col feed-list' style={{width: '95%', margin: '0 auto'}}>
          
          <StickyMenu buttonsList={stickyButtons} />
          {isCategorySelection && (
            <div className="category-squares" style={{paddingBottom: '2rem', marginTop: '0'}}>
              <div
                  className={`category-square ${selectedCategory === 1 ? 'selected category-1' : ''}`}
                  onClick={() => handleCategoryClick(1)}
              ></div>
              <div
                  className={`category-square ${selectedCategory === 2 ? 'selected category-2' : ''}`}
                  onClick={() => handleCategoryClick(2)}
              ></div>
              <div
                  className={`category-square ${selectedCategory === 3 ? 'selected category-3' : ''}`}
                  onClick={() => handleCategoryClick(3)}
              ></div>
              <div
                  className={`category-square ${selectedCategory === 4 ? 'selected category-4' : ''}`}
                  onClick={() => handleCategoryClick(4)}
              ></div>
            </div>
          )}
          <div className='posts-list flex-center-col list' style={{gap: '1rem', width: '100%'}}>
            {postList?.length ? <FeedPostsList list={postList} category={selectedCategory} /> : <Loader />}
          </div>
        </div>
      );
}

export default FeedPosts