import React, { useState, useRef } from 'react';
import { 
    SaveIdleIcon, UserIdle, EyeIcon, PaperplaneIcon, CommentIcon, StarIcon, 
    EditIcon, BackgroundIcon, StarIdleIcon, StarActiveIcon, CopyIdleIcon, PlaneIdleIcon, CommentIdleIcon, 
    PlaneActiveIcon,
    ThickBorderedStar
} from '../../../assets';
import './feedPost.css';

import CircledButton from '../../buttons/circled-button/CircledButton';

const StatIndex = ({type, image, value}) => (
    <span className={`feed-post--poster-bloc--stats--${type} feed-post--poster-bloc--stats--stat flex`}>
        <img src={image} alt={type} />
        <p>{value}</p>
    </span>
);

const FeedPost = ({post_id, title, text, time_of_creation, 
    date_of_creation, time_of_update, image, views, reposts, 
    comments, rating, category_id, created_by}, key) => {
    
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isStarActive, setIsStarActive] = useState(false); // State for main star
    const [showExtraStars, setShowExtraStars] = useState(false); // State for showing extra stars
    const [selectedRating, setSelectedRating] = useState(0); // State for selected rating
    const [showComments, setShowComments] = useState(false); // State for showing comments

    const contentRef = useRef(null)
    const findCategoryColor = (id) => {
        switch (id) {
            case 1:
                return 'green';
            case 2:
                return '#418CFC';
            case 3:
                return 'red';
            case 4:
                return '#FFD739';
            default:
                return 'black';
        }
    };

    let root = document.documentElement;
    root.style.setProperty('--category-color', findCategoryColor(category_id));
  
    const handleStarClick = () => {
        setIsStarActive(true); // Keep the main star active after rating
        setShowExtraStars(!showExtraStars); // Toggle the visibility of extra stars
    };

    const handleRatingClick = (rating) => {
        setSelectedRating(rating); // Set the selected rating
        setIsStarActive(true); // Keep the main star active
    };

    const handleCommentClick = () => {
        setShowComments(!showComments); // Toggle the visibility of comments
    };

    if (!title) return

    return (
        <>
            <div 
                key={post_id}
                className="feed-post flex-col" 
                onClick={() => setIsCollapsed(!isCollapsed)} 
                style={{borderColor: findCategoryColor(category_id)}}
            >
                <div className="feed-post--poster-bloc flex-col" style={{}}>
                    <div className="feed-post--poster-bloc--user flex-apart">
                        <img 
                            src={created_by?.profile_image?.substring(0,10) === "data:image" ? created_by?.profile_image : UserIdle} 
                            alt="User" 
                            style={{borderColor: findCategoryColor(category_id)}} 
                        />
                        <div className="feed-post--poster-bloc--user--info">
                            <p>{created_by?.profile_name ? created_by?.profile_name : created_by?.profile_email}</p>
                            <p>
                                {created_by?.profile_profession} 
                                <span style={{fontSize: '1rem'}}>
                                    {created_by?.profile_email && `(${created_by?.profile_email})`}
                                </span>
                            </p>
                        </div>
                        <div className="feed-post--poster-bloc--user--time flex-center-col">
                            <p>{time_of_creation}</p>
                            <p>{date_of_creation}.</p>
                        </div>
                    </div>
                    <div className="feed-post--poster-bloc--stats flex-apart">
                        <div className="feed-post--poster-bloc--stats--icons flex-apart">
                            <StatIndex type='views' image={EyeIcon} value={views} />
                            <StatIndex type='reposts' image={PaperplaneIcon} value={reposts} />
                            <StatIndex type='comments' image={CommentIcon} value={comments} />
                            <StatIndex type='rating' image={StarIcon} value={rating} />
                            <StatIndex type='attachments' image={BackgroundIcon} value={image ? 1 : 0} />
                        </div>
                        <div className="feed-post--poster-bloc--stats--modified">
                            {time_of_update && <StatIndex type='date' image={EditIcon} value={time_of_update} />}
                        </div>
                    </div>
                </div>
                <div className="feed-post--content-bloc">
                    <h2 className="title" style={{color: findCategoryColor(category_id)}}>{title}</h2>
                    <div className="content" ref={contentRef} 
                    style={{height: !isCollapsed ? `${contentRef?.current?.scrollHeight}px` : '0px'}}>
                    <p className={`description flex`}>{text}</p>
                    {image && <img src={image} style={{width: '100%', marginTop: '1rem'}} alt="Attachment" />}
                    </div>
                </div>
            </div>
            {!isCollapsed && (
                <>
                    <div className="feed-actions">
                        <div onClick={handleStarClick}>
                            <img src={isStarActive || selectedRating > 0 ? StarActiveIcon : ThickBorderedStar} alt="Star" />
                        </div>
                        <div>
                            <img src={SaveIdleIcon} alt="Save" />
                        </div>
                        <div>
                            <img src={CopyIdleIcon} alt="Copy" />
                        </div>
                        <div>
                            <img src={PlaneIdleIcon} alt="Send" />
                        </div>
                        <div onClick={handleCommentClick}>
                            <img src={CommentIdleIcon} alt="Comment" />
                        </div>
                    </div>
                    {showExtraStars && (
                        <div className="extra-stars">
                            {[...Array(5)].map((_, index) => (
                                <img 
                                    key={index} 
                                    src={selectedRating > index ? StarActiveIcon : StarIdleIcon} 
                                    alt={`Star ${index + 1}`} 
                                    onClick={() => handleRatingClick(index + 1)}
                                />
                            ))}
                        </div>
                    )}
                    {showComments && (
                        <div className="comments">
                            <textarea rows="5"></textarea>
                            <CircledButton idleImage={PlaneIdleIcon} activeImage={PlaneActiveIcon} handleButtonEffect={() => console.log("Add Comment")}/>
                          
                        </div>
                        
                    )}
                </>
            )}
        </>
    );
}

export default FeedPost;
