import { Route, Routes } from 'react-router-dom'

import { FeedPage, UserFeed, UserPage, PersonalSpace, ConnectedUsers, FeedPosts, RootPage, SettingsPage, NotificationsPage, SearchPage } from './pages'


import App from '../App'


import './index.css'

export const Routing = () => {

  return (
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/social' element={<RootPage />}>
        <Route path='feed' element={<FeedPage />}>
          <Route index element={<FeedPosts />} />
          <Route path='newpost' />
        </Route>
        <Route path='personal' element={<PersonalSpace />}>
          <Route index element={<UserFeed />} />
          <Route element={<ConnectedUsers />} path='user-bloc' />
          <Route path='user-page/:id' element={<UserPage />} />
          <Route path='settings' element={<SettingsPage />} />
        </Route>
        <Route path='notifications' element={<NotificationsPage />} />
        <Route path='search' element={<SearchPage />} />
      </Route>
    </Routes>
  )
}
