import { useEffect } from "react"
import UserBloc from "../blocs/user-bloc/UserBloc"


const ConnectedUsersList = ({list}) => {
  return (
    list?.map((user,key) => (
      <UserBloc item={user} key={key} />
    ))
  )
}

export default ConnectedUsersList