import React, { useState, useEffect } from "react";
import "./logoPage.css";
import android from "../picture/Android.png";
import apple from "../picture/Apple.png";
import logo from "../picture/Tempo.png";
import youtube from "../picture/Youtube.png";
import rectangularPlay from "../picture/rectangular-play.png";
import www from "../picture/Www.png";
import { useGlobalContext } from "../Context/Context";

const LogoPage = ({
  setCurrentPage,
  isInstallVisible,
  setIsInstallVisible,
}) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const {
    isAndroid,
    setIsAndroid,
    isIOS,
    setIsIOS,
    isFromHome,
    setLoggedUser,
  } = useGlobalContext();

  let onloadScale;
  let containerHeight;
  let containerWidth;

  let aspectRatioScreen = {
    widthScale: 37,
    heightScale: 18,
  };

  const ASPECT_RATIO_MAIN = 37 / 18;

  function resize(width, height, aspect) {
    if (width > height) {
      if (onloadScale > aspect) {
        containerHeight = height;
        containerWidth =
          (containerHeight * aspectRatioScreen.widthScale) /
          aspectRatioScreen.heightScale;
      } else {
        containerWidth = width;
        containerHeight =
          (containerWidth * aspectRatioScreen.heightScale) /
          aspectRatioScreen.widthScale;
      }
    } else {
      if (onloadScale > 1 / aspect) {
        containerHeight = window.outerHeight;
        containerWidth =
          (containerHeight * aspectRatioScreen.heightScale) /
          aspectRatioScreen.widthScale;
      } else {
        containerWidth = window.outerWidth;
        containerHeight =
          (containerWidth * aspectRatioScreen.widthScale) /
          aspectRatioScreen.heightScale;
      }
    }
    resizeElementsMain();
  }

  function resizeElementsMain() {
    let mainContainer = document.querySelector(".definePin-container");
    mainContainer.style.width = containerWidth + "px";
    mainContainer.style.height = containerHeight + "px";
  }

  window.addEventListener("load", () => {
    onloadScale = window.innerWidth / window.innerHeight;
    resize(window.innerWidth, window.innerHeight, ASPECT_RATIO_MAIN);
  });

  window.onresize = resizeHandler;

  function resizeHandler() {
    onloadScale = window.innerWidth / window.innerHeight;
    resize(window.innerWidth, window.innerHeight, ASPECT_RATIO_MAIN);
  }

  useEffect(() => {
    const interval = setTimeout(() => {
      handleSetCurrentPage();
    }, 4000000);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallVisible(true); // Show install buttons
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Detect if the app is installed (for PWA on mobile)
    const isStandalone =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone;

    if (!isStandalone) {
      // Detect the user's device
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (/android/.test(userAgent)) {
        setIsAndroid(true);
        setIsIOS(false); // Ensure only one is true
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        setIsIOS(true);
        setIsAndroid(false); // Ensure only one is true
      }
    } else {
      // Hide the install buttons if app is already installed
      setIsInstallVisible(false);
    }

    return () => {
      clearTimeout(interval);
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [setCurrentPage]);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;
    deferredPrompt.prompt();
    const choiceResult = await deferredPrompt.userChoice;
    if (choiceResult.outcome === "accepted") {
      console.log("User accepted the install prompt");
    } else {
      console.log("User dismissed the install prompt");
    }
    setDeferredPrompt(null);
    setIsInstallVisible(false); // Hide buttons after installation
  };

  const handleSetCurrentPage = () => {
    if (isFromHome) {
      setCurrentPage(4);
      setLoggedUser(JSON.parse(localStorage.getItem("editor-user")));
    } else {
      setCurrentPage(1);
    }
  };

  return (
    <div className="definePin-container">
      <div
        className="wrapper-logo"
        style={
          !isInstallVisible
            ? {
                justifyContent: "flex-end",
                paddingBottom: "10%",
              }
            : { justifyContent: "center" }
        }
      >
        {isInstallVisible && (
          <div className="android-apple">
            <img
              src={apple}
              alt="Install on iOS"
              onClick={isIOS ? handleInstallClick : null}
              className={isIOS ? "glow" : ""}
            />
            <img
              src={android}
              alt="Install on Android"
              onClick={isAndroid ? handleInstallClick : null}
              className={isAndroid ? "glow" : ""}
            />
          </div>
        )}
        <div
          className="logo"
          onClick={() => handleSetCurrentPage()}
          onTouchStart={() => handleSetCurrentPage()}
        >
          <img src={logo} alt="Logo" />
        </div>
        <div className="youtube-link">
          <img src={rectangularPlay} alt="Rectangle Youtube" />
          <img src={www} alt="Website" />
          <img src={youtube} alt="Youtube" />
        </div>
      </div>
      <div className="wrapper-logo-mobile">
        <div
          className="logo"
          onClick={() => setCurrentPage(1)}
          onTouchStart={() => setCurrentPage(1)}
        >
          <img src={logo} alt="Logo" />
        </div>
        <div className="wrapper-logo-mobile-left">
          {isInstallVisible && (
            <div className="android-apple">
              <img
                src={apple}
                alt="Install on iOS"
                onClick={isIOS ? handleInstallClick : null}
                className={isIOS ? "glow" : ""}
              />
              <img
                src={android}
                alt="Install on Android"
                onClick={isAndroid ? handleInstallClick : null}
                className={isAndroid ? "glow" : ""}
              />
            </div>
          )}
          <div className="youtube-link">
            <img src={rectangularPlay} alt="Rectangle YT" />
            <img src={www} alt="Website" />
            <img src={youtube} alt="Youtube" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoPage;
