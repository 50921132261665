import { useEffect, useState, useRef } from "react";
import ControlScreen from "../ControlScreen/ControlScreen";
import EnterCode from "../EnterCode/EnterCode";
import "./controlPassword.css";
import axios from "axios";
import CheckForTrustedContacts from "../CheckForTrustedContacts/CheckForTrustedContacts";
import SendCodeOnlyToMyself from "../SendCodeOnlyToMyself/SendCodeOnlyToMyself";
import SendMyselfOrTrustedUser from "../SendMyselfOrTrustedUser/SendMyselfOrTrustedUser";
import SendOrCopyMail from "../SendOrCopyMail/SendOrCopyMail";
import { useGlobalContext } from "../../Context/Context";

const ControlPassword = ({ setCurrentPage }) => {
  const [currentPagePassword, setCurrentPagePassword] = useState(0);
  const [usePatternAnswer, setUserPatternAnswer] = useState(undefined);
  const [haveTrustContact, setHaveTrustContact] = useState(undefined);
  const [usePattern, setUsePattern] = useState(undefined);
  const [correctPattern, setCorrectPattern] = useState(false);
  const [inputTrustContact, setInputTrustContact] = useState(undefined);
  const { baseUrl, controlEmail } = useGlobalContext();

  let onloadScale;
  let containerHeight;
  let containerWidth;

  let aspectRatioScreen = {
    widthScale: 37,
    heightScale: 18,
  };

  const ASPECT_RATIO_MAIN = 37 / 18;

  function resize(width, height, aspect) {
    if (width > height) {
      if (onloadScale > aspect) {
        containerHeight = height;
        containerWidth =
          (containerHeight * aspectRatioScreen.widthScale) /
          aspectRatioScreen.heightScale;
      } else {
        containerWidth = width;
        containerHeight =
          (containerWidth * aspectRatioScreen.heightScale) /
          aspectRatioScreen.widthScale;
      }
    } else {
      if (onloadScale > 1 / aspect) {
        containerHeight = window.outerHeight;
        containerWidth =
          (containerHeight * aspectRatioScreen.heightScale) /
          aspectRatioScreen.widthScale;
      } else {
        containerWidth = window.outerWidth;
        containerHeight =
          (containerWidth * aspectRatioScreen.widthScale) /
          aspectRatioScreen.heightScale;
      }
    }
    resizeElementsMain();
  }

  function resizeElementsMain() {
    let mainContainer = document.querySelector(".wrapper-control-password");
    mainContainer.style.width = containerWidth + "px";
    mainContainer.style.height = containerHeight + "px";
  }

  window.addEventListener("load", () => {
    onloadScale = window.innerWidth / window.innerHeight;
    resize(window.innerWidth, window.innerHeight, ASPECT_RATIO_MAIN);
  });

  window.onresize = resizeHandler;

  function resizeHandler() {
    onloadScale = window.innerWidth / window.innerHeight;
    resize(window.innerWidth, window.innerHeight, ASPECT_RATIO_MAIN);
  }

  console.log(controlEmail);
  useEffect(() => {
    const handleHaveTrustContact = async () => {
      try {
        const { data } = await axios.post(
          `${baseUrl}/control/checkIfUserHasTrustedContacts.php`,
          {
            user_email: controlEmail,
          }
        );
        console.log(data);
        if (data.status === 200) {
          setHaveTrustContact(data.has_trusted_contact);
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    handleHaveTrustContact();
    console.log(controlEmail);
  }, []);

  return (
    <div className="wrapper-control-password">
      {currentPagePassword === 0 && (
        <ControlScreen
          setCurrentPagePassword={setCurrentPagePassword}
          setUsePattern={setUsePattern}
          usePattern={usePattern}
          haveTrustContact={haveTrustContact}
          setCorrectPattern={setCorrectPattern}
          correctPattern={correctPattern}
          inputTrustContact={inputTrustContact}
          setInputTrustContact={setInputTrustContact}
          usePatternAnswer={usePatternAnswer}
          setUserPatternAnswer={setUserPatternAnswer}
        ></ControlScreen>
      )}
      {currentPagePassword === 1 && (
        <EnterCode setCurrentPage={setCurrentPage}></EnterCode>
      )}
      {currentPagePassword === 2 && (
        <CheckForTrustedContacts
          haveTrustContact={haveTrustContact}
          setCurrentPagePassword={setCurrentPagePassword}
          usePattern={usePattern}
          usePatternAnswer={usePatternAnswer}
          correctPattern={correctPattern}
        ></CheckForTrustedContacts>
      )}
      {currentPagePassword === 3 && (
        <SendCodeOnlyToMyself
          setCurrentPage={setCurrentPage}
          setCurrentPagePassword={setCurrentPagePassword}
        />
      )}
      {currentPagePassword === 4 && (
        <SendMyselfOrTrustedUser
          setCurrentPage={setCurrentPage}
          setCurrentPagePassword={setCurrentPagePassword}
        />
      )}
      {currentPagePassword === 5 && (
        <SendOrCopyMail setCurrentPagePassword={setCurrentPagePassword} />
      )}
    </div>
  );
};

export default ControlPassword;
