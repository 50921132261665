import { useEffect, useState } from 'react'
import './loader.css'
import { Link } from 'react-router-dom'

const Loader = () => {


  const [message, setMessage] = useState()

  useEffect(() => {
    setTimeout(() => {
      setMessage(<h1 style={{textAlign: 'center'}}>You have to be a member to view this page.<br /><Link to='/'>Sign in</Link></h1>)
    }, 2000);
  }, [])
  
  return (
    message ||
    <div className="loading-screen flex-center-col">
        <div className="clock">
            <div className="minutes"></div>
            <div className="hours"></div>
        </div>
    </div>
    
  )
}

export default Loader