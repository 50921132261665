import React, { useEffect, useState } from 'react';

import "./circledButton.css";
import { useNavigate } from 'react-router-dom';

function CircledButton({ idleImage, activeImage, toggle, type='button', children, handleButtonEffect = () => {console.log('button')}, extraStyling, current=false }) {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate()

  // Handle click on button
  const handleClick = () => {
    if (toggle) {
      setIsActive(!isActive);
      handleButtonEffect()
    } else {
      setIsActive(true);
      setTimeout(() => {setIsActive(false); handleButtonEffect()}, 500);  
    }
    if (current) {
      if (type === 'category-button') setIsActive(true)
      else {navigate(-1); console.log(current)}
    }
  };

  useEffect(() => {
    if (current) setIsActive(true)
    else setIsActive(false)
  }, [current])
  

  return (
    <button 
      type={type}
      className={`circled-button ${isActive ? 'active' : ''}`} 
      onClick={handleClick}
      style={{ backgroundColor: isActive ? 'var(--active-color)' : 'initial' }}
    >
      <img src={(isActive && activeImage) ? activeImage : idleImage} alt="Icon" style={extraStyling} />
      <div className="button-extra-child">{children}</div>
      
    </button>
  );
}

export default CircledButton;

